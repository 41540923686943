import { Flex, Text, Image } from "@chakra-ui/react"
import { useState } from "react"
import logo from "../assets/logo3.png"
import { useAccount } from "wagmi"
import { useNavigate } from "react-router-dom"

export default function NavBar({ mod4, setMod4 }) {
    const navigate = useNavigate()
    const { isConnected } = useAccount()
    const menu = ["About", "Features", "Pricing", "FAQs", "Contact Us", "Docs"]
    const [isMenu, setIsMenu] = useState(false)

    return (
        <>
        <Flex w="100%" py={["2", "3"]} justify="center" bg="rgba(255,255,255,0.8)" backdropFilter="blur(8px)" position="sticky" top="0" zIndex="10">
            <Flex w="100%" align="center" px={["5", "2%"]} className="container">
                <Flex cursor="pointer" align="center" direction="column" onClick={() => {
                    navigate("/")
                    window.scrollTo(0,0)
                }}>
                    <Image src={logo} w="auto" h="40px" />
                    <Text mt="1" fontSize={["9px", "11px"]}>META<Text as="span" fontWeight="700">GUARD</Text></Text>
                </Flex>

                <Flex display={["flex", "none"]} ml="auto" fontSize="30px" onClick={() => setIsMenu(true)}>
                    <i className="mdi mdi-menu"></i>
                </Flex>

                <Flex flex="1" align="center" mx="5" justify="center" display={["none", "flex"]}>
                    {
                        menu.map((item, index) => (
                            <Flex key={index} cursor="pointer" mr={(index + 1) < menu?.length && "5"} color="#4f4e4e" fontSize="13px" fontWeight="500" _hover={{ color: "#2f67f5", letterSpacing: "3px" }} transition="300ms ease-in-out" onClick={() => {
                                navigate("/")
                                if(index === 0) {
                                    const element = document.getElementById('s2')
                                    return window.scrollTo({
                                        top: element ? element.offsetTop - 100 : 0,
                                        behavior: 'smooth',
                                    })
                                }
                                else if(index === 3) {
                                    const element = document.getElementById('s3')
                                    return window.scrollTo({
                                        top: element ? element.offsetTop - 100 : 0,
                                        behavior: 'smooth',
                                    })
                                }
                                else if(index === 4) {
                                    if (window.tidioChatApi) {
                                        window.tidioChatApi.open()
                                    }
                                }
                                else if(index === 5) {
                                    const element = document.getElementById('s4')
                                    return window.scrollTo({
                                        top: element ? element.offsetTop - 100 : 0,
                                        behavior: 'smooth',
                                    })
                                }
                                else {
                                    const element = document.getElementById('s1')
                                    return window.scrollTo({
                                        top: element ? element.offsetTop - 20 : 0,
                                        behavior: 'smooth',
                                    })
                                }
                            }}>{item}</Flex>
                        ))
                    }
                </Flex>

                <Flex display={["none", "flex"]} cursor="pointer" bg="#2f67f5" padding="12px 20px" align="center" justify="center" color="#fff" _hover={{ bg: "#0747e8", letterSpacing: "3px" }} fontWeight="500" borderRadius="30px" transition="300ms ease-in-out" onClick={() => {
                    if(isConnected) {
                        setMod4(true)
                    }
                    else {
                        const element = document.getElementById('s1')
                        return window.scrollTo({
                            top: element ? element.offsetTop - 20 : 0,
                            behavior: 'smooth',
                        })
                    }
                }}>
                    {isConnected ? "Disconnect" : "Get Started"}
                </Flex>
            </Flex>
        </Flex>

        {
            isMenu &&
            <Flex zIndex='20' direction="column" w="100%" position="fixed" top="0" justify="flex-end" align="flex-end" h="100%">
                <Flex w="100%" bg="rgba(0,0,0,0.7)" backdropFilter="blur(8px)" position="absolute" h="100%" onClick={() => setIsMenu(false)}></Flex>
                <Flex w="80%" h="100%" px="5" py="12" direction="column" bg="#fff" data-aos="fade-left" data-aos-duration="500">
                <Flex w="100%" justify="space-between" mb="8" align="center" fontSize="30px">
                    <Image src={logo} h="30px" /> 
                    <i className="mdi mdi-close" style={{ cursor: "pointer" }} onClick={() => setIsMenu(false)}></i>
                </Flex>
                {
                        menu.map((item, index) => (
                            <Flex key={index} cursor="pointer" pb="4" mb="5" borderBottom={(index + 1) < menu?.length && "1px solid #c4c4c4"} color="#4f4e4e" fontSize="13px" fontWeight="500" _hover={{ color: "#2f67f5", letterSpacing: "3px" }} transition="300ms ease-in-out" onClick={() => {
                                setIsMenu(false)
                                navigate("/")
                                if(index === 0) {
                                    const element = document.getElementById('s2')
                                    return window.scrollTo({
                                        top: element ? element.offsetTop - 100 : 0,
                                        behavior: 'smooth',
                                    })
                                }
                                else if(index === 3) {
                                    const element = document.getElementById('s3')
                                    return window.scrollTo({
                                        top: element ? element.offsetTop - 100 : 0,
                                        behavior: 'smooth',
                                    })
                                }
                                else if(index === 4) {
                                    if (window.tidioChatApi) {
                                        window.tidioChatApi.open()
                                    }
                                }
                                else if(index === 5) {
                                    const element = document.getElementById('s4')
                                    return window.scrollTo({
                                        top: element ? element.offsetTop - 100 : 0,
                                        behavior: 'smooth',
                                    })
                                }
                                else {
                                    const element = document.getElementById('s1')
                                    return window.scrollTo({
                                        top: element ? element.offsetTop - 20 : 0,
                                        behavior: 'smooth',
                                    })
                                }
                            }}>{item}</Flex>
                        ))
                    }
                    <Flex cursor="pointer" bg="#2f67f5" padding="12px 20px" align="center" justify="center" color="#fff" _hover={{ bg: "#0747e8", letterSpacing: "3px" }} fontWeight="500" borderRadius="5px" transition="300ms ease-in-out" onClick={() => {
                        setIsMenu(false)
                        if(isConnected) {
                            setMod4(true)
                        }
                        else {
                            const element = document.getElementById('s1')
                            return window.scrollTo({
                                top: element ? element.offsetTop - 20 : 0,
                                behavior: 'smooth',
                            })
                        }
                    }}>
                        {isConnected ? "Disconnect" : "Get Started"}
                    </Flex>
                </Flex>
            </Flex>
        }
        </>
    )
}