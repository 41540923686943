import { Flex, Text, Image } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"


export default function Privacy() {
  const navigate = useNavigate()
    return (
        <Flex w="100%" py="100px">
            <Flex w="100%" px={["5", "20%"]} className="container" direction="column">
                <Text color="#2f67f5" fontWeight="700" data-aos="fade-down">MetaGuard</Text>
                <Text fontWeight="700" fontSize="30px" data-aos="fade-down">Privacy Policy</Text>


        

  <p>Last updated: [April 2023]</p>
    <div class="section">
    <p>MetaGuard Technologies (“MetaGaurd”, "us", "we", or "our") operates the MetaGaurd web application(s), website(s) and related services (collectively, the "Services").</p>
    <p>This Privacy Policy (this “Privacy Policy”) informs you of our policies regarding the collection, use and disclosure of Personal Information (defined below) when you use the Services and the choices you have associated with that data.</p>
    <p>By using the Services, you agree to the collection and use of information in accordance with this Privacy Policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions (available at <a onClick={() => navigate("/terms-of-use")} style={{ color: "#2f67f5", cursor: "pointer" }}>https://www.metaguard.app/terms-of-use</a>).</p>
  </div>

  <div class="section">
    <h2>Definitions</h2>
    <h3>Personal Information</h3>
    <p>For the purposes of this Privacy Policy, unless otherwise required by applicable law, “Personal Information” means any information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular individual consumer or household.</p>
    <h3>Usage Data</h3>
    <p>“Usage data” is data collected automatically either generated by the use of the Services or from the Services infrastructure itself (for example, the receiving address of a transaction).</p>
  </div>

  <div class="section">
    <h2>Information Collection</h2>
    <h3>Types of Personal Information Collected</h3>
    <p>Depending on how you interact with the Services, we may collect the following information about or from you:</p>
    <ul>
      <li>Personal Information and contact information, such as your name, e-mail address, your digital-wallet address, and any other information you submit through form(s) available on the Services.</li>
      <li>Communications between you and us. When you communicate with us, such as through e-mail or text message, we may collect and save a record of your communication and any Personal Information provided during the communication.</li>
      <li>Usage data and automatically collected technical information such as traffic data, location data, logs, and other communication data and the resources that you access and use the Services.</li>
      <li>Information concerning blockchain-based and/or digital-asset transactions effected by you in connection with your use of the Services, including, but not limited to, digital-asset wallet address(es), digital assets and/or so-called “smart contracts” transacted in and/or with, the website or other address from which the transaction was initiated, etc.</li>
      <li>Information about your computer and internet connection, including, but not limited to, the type of device you use, the IP address of your device, your operating system, information concerning the desktop and/or mobile application(s) you use (e.g., browser extension identification number(s), etc.), the type of Internet browser you use, unique device identifiers and other diagnostic data.</li>
    </ul>
    <h3>Sources of Personal Information</h3>
    <p>We collect Personal Information from various sources including data directly from you, from third parties and public sources, and from automated systems.</p>
    <h4>Direct interactions</h4>
    <p>You may give us your Personal Information by filling in forms or by corresponding with us by post, email, Discord, Twitter or otherwise. This includes data you provide when you:</p>
    <ul>
      <li>begin using and/or otherwise subscribe to the Services;</li>
      <li>participate in ways to improve customer satisfaction and customer experience;</li>
      <li>engage customer support;</li>
      <li>request marketing to be sent to you; and/or</li>
      <li>give us feedback or contact us.</li>
    </ul>
    <h4>Third parties</h4>
    <p>We may collect information collected and provided to us by our affiliate and other third parties, public records, third-party providers of services to us (e.g., fraud detection, identity verification, analytics and security), consumer data resellers, social networks, joint marketing partners and affiliate companies.</p>
    <h4>Automated technologies or interactions</h4>
    <p>As you interact with our Services, we may automatically collect technical data about your equipment, browsing actions and patterns. We collect this personal data by using log files and other similar technologies. Please see the Logs and Analytics section below for further details.</p>
  </div>

  <div class="section">
    <h2>Retention of Personal Information</h2>
    <p>Except to the extent prohibited by law, and subject to this Privacy Policy, we will retain and use your Personal Information for as long as it is needed for the relevant purpose, including to provide you with any of the Services, communications, information you have requested, or access to the Services, to document our business relationship with you, and as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
    <p>If you do not provide the Personal Information that is necessary, we may not be able to respond to your query or provide the Services to you. In the case of email marketing, you can choose to opt-out, refuse or stop the processing at any time.</p>
  </div>

  <div class="section">
    <h2>Use of Personal Information</h2>
    <p>We, and our authorized third parties, will only process your Personal Information where we have legal grounds to do so. We use the Personal Information that we collect for various purposes, including:</p>
    <ul>
      <li>To provide and maintain the Services. Our lawful basis is to fulfill any contractual terms with you.</li>
      <li>To notify you about changes to the Services. Our lawful basis is to fulfill any contractual terms with you.</li>
      <li>To allow you to participate in interactive features of the Services when you choose to do so. Our lawful basis is our legitimate interests and contract of providing the Services to you.</li>
      <li>To provide customer support. Our lawful basis is our legitimate interests as a business in responding to and keeping a record of correspondence.</li>
      <li>To gather analysis or valuable information so that we can improve the Services. Our lawful basis is our legitimate business interests in understanding and improving our services.</li>
      <li>To monitor the usage of the Services. Our lawful basis is our legitimate business interests in understanding and improving our services.</li>
      <li>To detect, prevent and address technical issues. Our lawful basis is to fulfill any contractual terms with you by providing technical support and our legitimate business interests in protecting the Services.</li>
      <li>To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information. Our lawful basis is your consent to choose to subscribe to any newsletter or marketing (and you can unsubscribe at any time).</li>
    </ul>
  </div>

  <div class="section">
    <h2>Transfer of Personal Information Overseas</h2>
    <p>We are a global company and will collect and process your Personal Information around the globe. Your information, including Personal Information, may be transferred to - and maintained on - computers located outside of your state, province, country or other governmental jurisdiction from where it was collected and the applicable data protection laws may differ from those of your jurisdiction. We ensure that appropriate contractual protections are in place with such third parties in line with applicable law, including appropriate European Union / United Kingdom standard contractual clauses where necessary. Please contact us if you would like more details about our safeguards for data transfers.</p>
  </div>

  <div class="section">
    <h2>Disclosure of Personal Information</h2>
    <p>Personal Information may be shared in the following circumstances:</p>
    <h3>Service providers</h3>
    <p>We may disclose Personal Information to third-party service providers who use this information to perform services for us, such as hosting providers, auditors, advisors, consultants, analytics, customer service and/or support providers and other similar service providers.</p>
    <h3>Law Enforcement</h3>
    <p>Under certain circumstances, we may be required to disclose your Personal Information if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
    <h3>Business Transfers</h3>
    <p>In the event of a merger, acquisition or asset sale, your Personal Information may be transferred. We will provide notice before your Personal Information is transferred and becomes subject to a different Privacy Policy.</p>
    <h3>Other Disclosures</h3>
    <p>We may also disclose your Personal Information to our affiliates or subsidiaries; to contractors, service providers, and other third parties we use to support our business; to fulfill the purpose for which you provide it; for the purpose of including your company’s logo on our website; for any other purpose disclosed by us when you provide the information; with your consent in any other cases; if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of MetaGaurd, our customers, or others.</p>
  </div>

  <div class="section">
    <h2>Security of Personal Information</h2>
    <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>
  </div>

  <div class="section">
    <h2>Your Data Protection Rights</h2>
    <p>Depending on your jurisdiction, you may have the following data protection rights:</p>
    <ul>
      <li>The right to access – You have the right to request copies of your Personal Information from us.</li>
      <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete.</li>
      <li>The right to erasure – You have the right to request that we erase your Personal Information, under certain conditions.</li>
      <li>The right to restrict processing – You have the right to request that we restrict the processing of your Personal Information, under certain conditions.</li>
      <li>The right to object to processing – You have the right to object to our processing of your Personal Information, under certain conditions.</li>
      <li>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
      <li>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our contact information below.</li>
    </ul>
  </div>

  <div class="section">
    <h2>Children's Privacy</h2>
    <p>Our Services do not address anyone under the age of 13 ("Children").</p>
    <p>We do not knowingly collect personally identifiable information from anyone under the age of 13. If you are a parent or guardian and you are aware that your Child has provided us with Personal Information, please contact us. If we become aware that we have collected Personal Information from children without verification of parental consent, we take steps to remove that information from our servers.</p>
  </div>
            </Flex>
        </Flex>
    )
}