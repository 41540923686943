import './App.css';
import { ChakraProvider, Flex } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WagmiProvider } from 'wagmi';
import { config } from './wagmi';
import Landing from './landing';
import { BrowserRouter, Routes, Route, Link, useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import StopPlaying from './stopplaying'
import NavBar from './components/navbar';
import Footer from './components/footer';
import { useState } from 'react';
import Privacy from './privacy';
import Terms from './terms';


const queryClient = new QueryClient()



const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top:0, left:0, behavior: "instant" });
  }, [location.pathname]);
  return children
}

function App() {
  const [mod4, setMod4] = useState(false)
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <StopPlaying>
          <ChakraProvider>
            <Flex w="100%" direction="column" fontSize="15px">
              <BrowserRouter>
                <Wrapper>
                  <NavBar mod4={mod4} setMod4={setMod4} />
                  <Routes>
                    <Route path="/" element={<Landing mod4={mod4} setMod4={setMod4} />} />
                    <Route path="/privacy-policy" element={<Privacy />} />
                    <Route path="/terms-of-use" element={<Terms />} />
                  </Routes>
                  <Footer />
                </Wrapper>
              </BrowserRouter>
            </Flex>
          </ChakraProvider>
        </StopPlaying>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
