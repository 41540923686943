import { Flex, Text, Image } from "@chakra-ui/react"
import { useState } from "react"
import logo from "../assets/logo2.png"
import { useNavigate } from "react-router-dom"

export default function Footer() {
    const navigate = useNavigate()

    return (
        <Flex w="100%" justify="center" bg="#2f67f512">
            <Flex w="100%" className="container" pt="80px" pb={["50px", "80px"]} px={["5", "2%"]} direction="column">
                <Flex w="100%" justify="space-between" direction={["column", "row"]}>
                    <Flex w={["100%", "30%"]} direction="column">
                        <Flex><Image src={logo} h="80px" /></Flex>
                        <Text mt="4" color="#4f4e4e" fontSize="13px">At Meta Guard, our mission is to provide top-notch security for your crypto assets, ensuring you can trade and invest without worries. </Text>
                    </Flex>
                    <Flex mt={["8", "0"]} w={["100%", "30%"]} justify={["flex-start", "center"]}>
                        <Flex direction="column">
                            <Text fontWeight="700" mb="2" fontSize="18px">Quick links</Text>
                            {
                                ["Get Started", "About", "Faq", "Testimonies", "Pricing and Features"].map((item, index) => (
                                    <Text cursor="pointer" key={index} color="#4f4e4e" fontSize="13px" mb="3" transition="300ms ease-in-out" _hover={{ color: "#2f67f5" }} onClick={() => {
                                        navigate("/")
                                        if(index === 1) {
                                            const element = document.getElementById('s2')
                                            return window.scrollTo({
                                                top: element ? element.offsetTop - 100 : 0,
                                                behavior: 'smooth',
                                            })
                                        }
                                        else if(index === 2) {
                                            const element = document.getElementById('s3')
                                            return window.scrollTo({
                                                top: element ? element.offsetTop - 100 : 0,
                                                behavior: 'smooth',
                                            })
                                        }
                                        else if(index === 3) {
                                            const element = document.getElementById('s5')
                                            return window.scrollTo({
                                                top: element ? element.offsetTop - 100 : 0,
                                                behavior: 'smooth',
                                            })
                                        }
                                        else {
                                            const element = document.getElementById('s1')
                                            return window.scrollTo({
                                                top: element ? element.offsetTop - 20 : 0,
                                                behavior: 'smooth',
                                            })
                                        }
                                    }}>{item}</Text>
                                ))
                            }
                        </Flex>
                    </Flex>
                    <Flex mt={["8", "0"]} w={["100%", "30%"]} justify={["flex-start", "center"]}>
                        <Flex direction="column">
                            <Text fontWeight="700" mb="2" fontSize="18px">Get in Touch</Text>
                            <Flex align="center" mt="2">
                                <Text color="#4f4e4e" fontSize="30px" cursor="pointer" mr="4" transition="300ms ease-in-out" _hover={{ color: "#2f67f5" }} onClick={() => window.open("https://x.com/realmetaguard?s=21")}><i className="mdi mdi-twitter"></i></Text>
                                
                                <Text onClick={() => window.open("mailto: support@metaguard.tech")} color="#4f4e4e" fontSize="30px" cursor="pointer" mr="4" transition="300ms ease-in-out" _hover={{ color: "#2f67f5" }} ><i className="mdi mdi-email-arrow-right"></i></Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>

                <Flex mt="10" pt="12" borderTop="1px solid #c4c4c4" justify="space-between" fontSize="13px" direction={["column", "row"]}>
                    <Text display={["none", 'block']} textAlign={["center", "left"]}>&copy; 2024 MetaGuard Technologies. All Rights reserved.</Text>
                    <Flex w={["100%", "auto"]} justify="space-between">
                        <Text cursor="pointer" color="#4f4e4e" fontSize="13px" mb="3" transition="300ms ease-in-out" _hover={{ color: "#2f67f5" }} mr="5" onClick={() => navigate("/privacy-policy")}>Privacy Policy</Text>
                        <Text cursor="pointer" color="#4f4e4e" fontSize="13px" mb="3" transition="300ms ease-in-out" _hover={{ color: "#2f67f5" }} onClick={() => navigate("/terms-of-use")}>Terms & Conditions</Text>
                    </Flex>
                    
                    <Text display={["block", 'none']} mt="8" textAlign="left">&copy; 2024 MetaGuard Technologies. All Rights reserved.</Text>
                </Flex>
            </Flex>
        </Flex>
    )
}