import { Flex, Text, Image } from "@chakra-ui/react"


export default function Terms() {
    return (
        <Flex w="100%" py="100px">
            <Flex w="100%" px={["5", "20%"]} className="container" direction="column">
                <Text color="#2f67f5" fontWeight="700" data-aos="fade-down">MetaGuard</Text>
                <Text fontWeight="700" fontSize="30px" data-aos="fade-down">Terms of Use</Text>


  <p>Last updated: [April 2023]</p>

  <div class="section">
    <p>Welcome to Meta Guard! These terms and conditions outline the rules and regulations for the use of Meta Guard's website and services.</p>
    <p>By accessing this website we assume you accept these terms and conditions in full. Do not continue to use Meta Guard's website or services if you do not accept all of the terms and conditions stated on this page.</p>
  </div>

  <div class="section">
    <h2>Introduction</h2>
    <p>These terms and conditions govern your use of this website; by using this website, you accept these terms and conditions in full. If you disagree with these terms and conditions or any part of these terms and conditions, you must not use this website.</p>
  </div>

  <div class="section">
    <h2>License to Use Website</h2>
    <p>Unless otherwise stated, Meta Guard and/or its licensors own the intellectual property rights published on this website and materials used on Meta Guard. Subject to the license below, all these intellectual property rights are reserved.</p>
    <p>You may view, download for caching purposes only, and print pages, files or other content from the website for your own personal use, subject to the restrictions set out below and elsewhere in these terms and conditions.</p>
    <p>You must not:</p>
    <ul>
      <li>Republish material from this website (including republication on another website);</li>
      <li>Sell, rent or sub-license material from the website;</li>
      <li>Show any material from the website in public;</li>
      <li>Reproduce, duplicate, copy or otherwise exploit material on this website for a commercial purpose;</li>
      <li>Edit or otherwise modify any material on the website;</li>
      <li>Redistribute material from this website except for content specifically and expressly made available for redistribution.</li>
    </ul>
  </div>

  <div class="section">
    <h2>Acceptable Use</h2>
    <p>You must not use this website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of Meta Guard or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.</p>
    <p>You must not use this website to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software.</p>
    <p>You must not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to this website without Meta Guard’s express written consent.</p>
  </div>

            </Flex>
        </Flex>
    )
}