import { http, createConfig } from "wagmi";
import { arbitrum, mainnet, sepolia, bsc, polygon, optimism, avalanche, linea, goerli, pulsechain, fantom } from 'wagmi/chains'
import { metaMask, coinbaseWallet, injected, walletConnect } from 'wagmi/connectors'


export const config = createConfig({
    chains: [mainnet, arbitrum,sepolia,bsc,polygon,optimism,avalanche,linea,goerli,pulsechain, fantom],
    connectors: [
        metaMask({
          dappMetadata: {
            name: "Meta Guard",
            url: "https://metaguard.io",
          }
        }),
        coinbaseWallet({ appName: 'Meta Guard' }),
        walletConnect({ projectId: '7cffbcf3a3827c441fc5a3099341b34b' }),
      ],
    ssr: false,
    transports: {
        [mainnet.id]: http(),
        [fantom.id]: http(),
        [goerli.id]: http(),
        [bsc.id]: http(),
        [optimism.id]: http(),
        [avalanche.id]: http(),
        [linea.id]: http(),
        [pulsechain.id]: http(),
        [polygon.id]: http(),
        [sepolia.id]: http(),
        [arbitrum.id]: http('https://arbitrum-mainnet.infura.io/v3/b654f7e428fc471da81b871b25f00113'),
    },
  })